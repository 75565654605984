
<template>
  <div id="page-wrapper">
    <div id="overlay">
      <div class="v-container">
        <div class="signupform">
          <form id="signinform" @submit.prevent="submit">
            <v-card class="form">
              <v-card-title> Reset Your Password </v-card-title>
              <v-card-text>
                <p>
                  Enter your new password here and submit. You'll then be able
                  to log in with the new password.
                </p>
                <v-text-field
                  v-model="password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="password"
                  :error-messages="passwordErrors"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                />
                <v-text-field
                  v-model="repeatPassword"
                  type="password"
                  name="repeat-password"
                  id="repeat-password"
                  placeholder="re-type new password to make sure"
                  :error-messages="repeatPasswordErrors"
                  @input="$v.repeatPassword.$touch()"
                  @blur="$v.repeatPassword.$touch()"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn :disabled="this.$v.$anyError" type="submit"
                  >Submit</v-btn
                >
              </v-card-actions>
            </v-card>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    password: { required, minLength: minLength(7) },
    repeatPassword: { sameAsPassword: sameAs("password") },
  },
  name: "SignUpForm",
  watch: {
    response(newValue) {
      if (newValue.status) {
        this.$router.push({ path: "/signin" });
      }
    },
  },
  data() {
    return {
      repeatPassword: null,
      password: null,
    };
  },
  computed: {
    response() {
      return this.$store.state.response;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      !this.$v.password.minLength &&
        errors.push("Password must be at least 7 characters");
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatPassword.$dirty) return errors;
      !this.$v.repeatPassword.sameAsPassword &&
        errors.push("Passwords must match. Try again.");
      return errors;
    },
  },
  methods: {
    submit() {
      let payload = {
        resetToken: this.$route.params.token,
        password: this.password,
      };
      this.$store.dispatch("resetPassword", payload);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.signupform {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
#page-wrapper {
  padding-top: 100px;
  background-color: black;
  height: 100vh;
  /* background-image:  url('../../assets/forgot.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
#overlay {
  color: black;
  height: 100vh;
}
.form {
  opacity: 1;
}
.alternate-text {
  color: white;
  margin-top: 10px;
}
.alternate-text a {
  color: white;
}
</style>
