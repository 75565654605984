
<template>
  <div id="page-wrapper">
    <div id="overlay">
      <div class="v-container">
      <div class="signupform">
        <form id="signinform" @submit.prevent="submit">
          <v-card class="form">
            <v-card-title>
              Request a password reset
            </v-card-title>
            <v-card-text>
                <p>Enter your account email and we'll send you a password reset link.</p>
              <v-text-field
                v-model="email"
                required
                type="text"
                name="email"
                id="email"
                placeholder="email"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </form>
        <p class="alternate-text">
          Already have an account?
          <router-link to="/signin">Sign in</router-link>
        </p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpForm",
//   watch: {
//     response(newValue) {
//       if (newValue.status) {
//         this.$router.push({ path: "/signin" });
//       }
//     }
//   },
  data() {
    return {
      email: null,
      password: null
    };
  },
  computed: {
    response() {
      return this.$store.state.response;
    }
  },
  methods: {
    submit() {
      let payload = {
        email: this.email,
      };
      this.$store.dispatch("forgotPassword", payload);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.signupform {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
#page-wrapper {
  padding-top: 100px;
  background-color: black;
  height: 100vh;
  background-image:  url('../../assets/forgot.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
#overlay {
  color: black;
  height: 100vh;
}
.form {
  opacity: 1;
}
.alternate-text{
  color: white;
  margin-top: 10px;
}
.alternate-text a {
  color: white;
}
</style>
