<template>
  <v-container>
    <a @click.prevent="$router.push({ path: '/users' })">Back to Users Page</a>
    <h1>User detail:</h1>
    <div v-if="user">
      <h2>Email: {{ user.email }}</h2>
      <h3>Role: {{ user.role }}</h3>
      <p>Id: {{ user._id }}</p>
      <button @click="deleteUser">Delete User</button>
    </div>
  </v-container>
</template>

<script>
import { baseURL } from "@/components/http-common";

export default {
  data() {
    return {
      baseURL: baseURL,
    };
  },
  methods: {
    deleteUser() {
      this.$store.dispatch("deleteUser", this.user._id);
      this.$router.push({ path: "/users" });
    },
  },
  computed: {
    user() {
      return this.$store.state.userStore.users.find((user) => {
        return user._id === this.$route.params.id;
      });
    },
  },
};
</script>

<style scoped>
.user-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.user-grid img {
  max-height: 150px;
  width: auto;
}
.user-grid > div {
  border: 1px solid #e7e7e7;
}
a {
  text-decoration: underline;
  cursor: pointer;
}
</style>